import Vue from 'vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
import moment from 'moment'
import 'normalize.css'
import App from './App'
import './assets/css/style.css'
import api from './api'
import Sticky from 'vue-sticky-directive'
Vue.use(Sticky)

Vue.config.productionTip = false

moment.locale('zh-cn')

Vue.prototype.$api=api

Vue.use(ElementUI, {size: 'medium'})

new Vue({
    store,
    router,
    moment,
    render: h => h(App),
}).$mount('#app')
