import Vue from 'vue'
import Router from 'vue-router'

// push
const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
// replace
const VueRouterReplace = Router.prototype.replace
Router.prototype.replace = function replace(to) {
    return VueRouterReplace.call(this, to).catch(err => err)
}
Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        /*{
            path: '/',
            component: () => import('../components/Home'),
        },*/
        {
            path: '/',
            redirect: '/zhanhui/home',
        },
        {
            path: '/zhanhui',
            redirect: '/zhanhui/home',
        },
        {
            path: '/zhanhui/home',
            component: () => import(/* webpackChunkName: "zhanhui" */ '../components/ZhanhuiHome'),
        },
        {
            path: '/zhanhui/industry-home',
            component: () => import(/* webpackChunkName: "zhanhui" */ '../components/ZhanhuiIndustryHome'),
        },
        {
            path: '/zhanhui/list',
            component: () => import(/* webpackChunkName: "zhanhui" */ '../components/ZhanhuiList'),
        },
        {
            path: '/zhanhui/details',
            component: () => import(/* webpackChunkName: "zhanhui" */ '../components/ZhanhuiDetails'),
        },
        {
            path: '/huiyi/list',
            component: () => import(/* webpackChunkName: "huiyi" */ '../components/HuiyiList'),
        },
        {
            path: '/huiyi/details',
            component: () => import(/* webpackChunkName: "huiyi" */ '../components/HuiyiDetails'),
        },
        {
            path: '/hxe-changguan/list',
            component: () => import(/* webpackChunkName: "hxe-changguan" */ '../components/HxeChangguanList'),
        },
        {
            path: '/hxe-changguan/details',
            component: () => import(/* webpackChunkName: "hxe-changguan" */ '../components/HxeChangguanDetails'),
        },
        {
            path: '/changguan',
            redirect: '/changguan/home',
        },
        {
            path: '/changguan/home',
            component: () => import(/* webpackChunkName: "changguan" */ '../components/ChangguanHome'),
        },
        {
            path: '/changguan/list',
            component: () => import(/* webpackChunkName: "changguan" */ '../components/ChangguanList'),
        },
        {
            path: '/changguan/details',
            component: () => import(/* webpackChunkName: "changguan" */ '../components/ChangguanDetails'),
        },
        {
            path: '/company',
            redirect: '/company/home',
        },
        {
            path: '/company/home',
            component: () => import(/* webpackChunkName: "company" */ '../components/CompanyHome'),
        },
        {
            path: '/company/list',
            component: () => import(/* webpackChunkName: "company" */ '../components/CompanyList'),
        },
        {
            path: '/company/details',
            component: () => import(/* webpackChunkName: "company" */ '../components/CompanyDetails'),
        },
        {
            path: '/ztb/list',
            component: () => import(/* webpackChunkName: "ztb" */ '../components/ZtbList'),
        },
        {
            path: '/zcfg',
            redirect: '/zcfg/home',
        },
        {
            path: '/zcfg/home',
            component: () => import(/* webpackChunkName: "zcfg" */ '../components/ZcfgHome'),
        },
        {
            path: '/zcfg/list',
            component: () => import(/* webpackChunkName: "zcfg" */ '../components/ZcfgList'),
        },
        {
            path: '/report',
            redirect: '/report/list',
        },
        {
            path: '/report/list',
            component: () => import(/* webpackChunkName: "report" */ '../components/ReportList'),
        },
        {
            path: '/report/ufilist',
            component: () => import(/* webpackChunkName: "report" */ '../components/UFIReportList'),
        },
        {
            path: '/mof/list',
            component: () => import(/* webpackChunkName: "mof" */ '../components/MofList'),
        },
        {
            path: '/macro-economy',
            redirect: '/macro-economy/home',
        },
        {
            path: '/macro-economy/home',
            component: () => import(/* webpackChunkName: "macro-economy" */ '../components/MacroEconomy'),
        },
        {
            path: '/macro-economy/news',
            component: () => import(/* webpackChunkName: "macro-economy" */ '../components/TjjNewsList'),
        },
        {
            path: '/demo/pie-chart',
            component: () => import(/* webpackChunkName: "demo" */ '../components/demo/PieChart'),
        },
        {
            path: '/demo/bar-chart',
            component: () => import(/* webpackChunkName: "demo" */ '../components/demo/BarChart'),
        },
        {
            path: '/demo/map-chart',
            component: () => import(/* webpackChunkName: "demo" */ '../components/demo/MapChart'),
        },
        {
            path: '/demo/province-map-chart',
            component: () => import(/* webpackChunkName: "demo" */ '../components/demo/ProvinceMapChart'),
        },
        {
            path: '/demo/map',
            component: () => import(/* webpackChunkName: "demo" */ '../components/demo/MapContainer'),
        },
        {
            path: '*',
            component: () => import('../components/NotFound')
        }
    ]
})
export default router
