<template>
  <div id="app" :style="extraStyle">
    <div class="top-nav-container">
      <div class="top-nav">
        <div class="top-nav-left">
          <img class="top-nav-logo" src="./assets/images/logoRET.png">
          <router-link v-for="item in menu" :key="item.name" :to="item.path"
                       :class="{'top-nav-menu':true,'top-nav-menu-selected':item===currentMenu}">{{item.name}}</router-link>
        </div>
        <div class="top-nav-right">
          <img src="./assets/images/ss/index-1.png">
        </div>
      </div>
    </div>
    <div class="top-sub-nav" v-if="currentMenu&&currentMenu.subMenu">
      <router-link v-for="item in currentMenu.subMenu" :key="item.name" :to="item.path" class="top-sub-nav-link">{{item.name}}</router-link>
    </div>
    <router-view></router-view>
    <div class="footer">版权所有</div>
  </div>
</template>
<script>
import './echarts/customed'

import { THEME_KEY } from "vue-echarts";


export default {
  name: 'App',
  provide: {
    [THEME_KEY]: 'customed'
  },
  data(){
    return {
      menu:[
        /*{
          name:'首页',
          path:'/'
        },*/
        {
          name:'会展库',
          path:'/zhanhui',
          subMenu:[
            {
              name:'会展综览',
              path:'/zhanhui/home'
            },
            {
              name:'行业会展分析',
              path:'/zhanhui/industry-home'
            },
            {
              name:'会展活动',
              path:'/zhanhui/list'
            },
            {
              name:'会议信息',
              path:'/huiyi/list'
            },
          ]
        },
        {
          name:'场馆库',
          path:'/changguan',
          subMenu:[
            {
              name:'场馆综览',
              path:'/changguan/home'
            },
            {
              name:'场馆列表',
              path:'/changguan/list'
            },
            {
              name:'会议酒店',
              path:'/hxe-changguan/list'
            },
          ]
        },
        {
          name:'企业库',
          path:'/company',
          subMenu:[
            {
              name:'企业综览',
              path:'/company/home'
            },
            {
              name:'企业列表',
              path:'/company/list'
            },
          ]
        },
        {
          name:'会展招投标',
          path:'/ztb/list'
        },
        {
          name:'政策法规',
          path:'/zcfg',
          subMenu:[
            {
              name:'政策分析',
              path:'/zcfg/home'
            },
            {
              name:'政策信息',
              path:'/zcfg/list'
            },
          ]
        },
        {
          name:'行业研究',
          path:'/report',
          subMenu:[
            {
              name:'国内研报',
              path:'/report/list'
            },
            {
              name:'UFI研报',
              path:'/report/ufilist'
            }
          ]
        },
        {
          name:'展前备案',
          path:'/mof/list'
        },
        {
          name:'宏观经济',
          path:'/macro-economy',
          subMenu:[
            {
              name:'经济指标',
              path:'/macro-economy/home'
            },
            {
              name:'统计公报',
              path:'/macro-economy/news'
            }
          ]
        },
      ]
    }
  },
  computed: {
    extraStyle () {
      if (this.$route.path.startsWith('/excerpt') || this.$route.path.startsWith('/memorabilia')) {
        return {paddingLeft: 0}
      } else {
        return null
      }
    },
    currentMenu(){
      for(const menu of this.menu){
        if(menu.path===this.$route.path){
          return menu
        }
        if(menu.subMenu&&menu.subMenu.some(i=>i.path===this.$route.path)){
          return menu
        }
      }
      return null
    }
  }
}
</script>
<style>
  #app{
    height: 100%;
    width: 100%;
    /*padding-left: calc(100vw - 100%);*/
    box-sizing: border-box;
    font-size: 16px;
    background-color: #F5F5F5;
  }
</style>
