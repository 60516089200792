import axios from 'axios'
import qs from 'qs'
import {Message} from 'element-ui';

const instance = axios.create({
    withCredentials: true,
    paramsSerializer: function (params) {
        return qs.stringify(params, {arrayFormat: 'repeat'})
    }
})

instance.interceptors.request.use(async function (config) {
    return config
}, function (error) {
    return Promise.reject(error)
})

instance.interceptors.response.use(function (response) {
    if (response.data) {
        return response.data
    }
    return response
}, function (error) {
    if (error.response) {
        if (error.response.status === 401) {
            document.location.reload()
        } else if (error.response.data && error.response.data.message) {
            Message.error(error.response.data.message)
        } else {
            Message.error('请求出错：' + error)
        }
    } else {
        Message.error('请求出错：' + error)
    }
    return Promise.reject(error)
})

export default instance
