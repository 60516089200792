import axios from "./axios";
import config from "../config";

function listZhanhui(params) {
    console.log(params)
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/'+encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size)
            +'/' +encodeURIComponent(params.sort)+'/'
            + (params.startDate? encodeURIComponent(params.startDate) +'/':'')
            + (params.endDate? encodeURIComponent(params.endDate) +'/' :'')
            + (params.type ? encodeURIComponent(params.type)+'/' : "null/")
            + (params.area ? encodeURIComponent(params.area) : "null")
            + (params.jbcg?'/' + encodeURIComponent(params.jbcg) :'/null')
            + (params.state?'/' + encodeURIComponent(params.state) :'/null')
            + (params.sshy && params.length>0?'/' + encodeURIComponent(params.sshy.join(',')):'/null')
            + (params.province && params.length>0?'/' + encodeURIComponent(params.province.join(',')):'/null'),
        method: 'GET',
    })
}

function listZhanhui1(params) {
    console.log(params)
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/'+encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size)
            +'/' +encodeURIComponent(params.jbcg)+'/'
            + encodeURIComponent(params.opening),
        method: 'GET',
    })
}

function getZhanhuiProvinceStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/province-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            + encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}

function getZhanhuiCityStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/city-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            + encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}

function getZhanhuiById(id) {
    return axios({
        url: config.backendUrl + 'zhanhui/'+id,
        method: 'GET',
    })
}

function listZhanhuiNews(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/zhanhui-news/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function listChangguanNews(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/changguan-news/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function getChangguanById(id) {
    return axios({
        url: config.backendUrl + 'changguan/'+id,
        method: 'GET',
    })
}
function getChangguanByName(name) {
    return axios({
        url: config.backendUrl + 'changguan/get-by-name',
        method: 'GET',
        params:{name}
    })
}
function getZhanhuiMjStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/mj-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            + encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}
function getZhanhuiSshyStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/sshy-stat/'
            + (params.startDate?encodeURIComponent(params.startDate)+'/':'')
            + (params.endDate?encodeURIComponent(params.endDate)+'/':'')
            + (params.type?encodeURIComponent(params.type)+'/':'')
            + (params.area ? encodeURIComponent(params.area) : "null")
            + (params.jbcg ? '/' + encodeURIComponent(params.jbcg) : ''),
        method: 'GET',
    })
}
function getZhanhuiJbzqStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/jbzq-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            + encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}
function listChangguan(params) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size) +'/'
            + (('minMj' in params) && params.minMj!== undefined ?encodeURIComponent(params.minMj):'null') +'/'
            + (('maxMj' in params) && params.maxMj!== undefined ?encodeURIComponent(params.maxMj):'null')
            + (params.sort? '/'+ encodeURIComponent(params.sort) : ''),
        method: 'GET',
    })
}
function getChangguanProvinceStat(year) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/province-stat/' + year,
        method: 'GET',
    })
}

function getChangguanCityStat(year,province) {
    return axios({
        url: config.backendUrl + 'changguan/city-stat',
        method: 'GET',
        params:{year,province}
    })
}
function getChangguanMjStat() {
    return axios({
        url: config.backendUrl + 'changguan/mj-stat',
        method: 'GET',
    })
}
function listChangguanKzl(params) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/changguan-kzl/'
            + params.startDate +'/' + params.endDate +'/' + params.limit,
        method: 'GET',
    })
}
function listAreaKzl(params) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/area-kzl/'
            + params.startDate +'/' + params.endDate,
        method: 'GET',
    })
}
function statChangguanByZhanhuiCount(limit) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/stat-by-zhanhui-count/' + limit ,
        method: 'GET',
    })
}
function statChangguanByZhanhuiMj(limit) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/stat-by-zhanhui-mj/' + limit,
        method: 'GET',
    })
}
function listQaData(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/qa/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size)
            + (params.comCode? '/'+ encodeURIComponent(params.comCode.join(',')):''),
        method: 'GET',
    })
}
function listFunding(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/funding/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function listPenalty(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/penalty/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function listBidding(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/bidding/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size) +'/'
            + (params.startDate? encodeURIComponent(params.startDate) +'/':'')
            + (params.endDate? encodeURIComponent(params.endDate) +'/' :'')
            + (params.htmcLike? encodeURIComponent(params.htmcLike):'null') +'/'
            + (params.partyaLike? encodeURIComponent(params.partyaLike):'null') +'/'
            + (params.partybLike? encodeURIComponent(params.partybLike):'null') +'/'
            + (params.minHtMoney? encodeURIComponent(params.minHtMoney):'null') +'/'
            + (params.maxHtMoney? encodeURIComponent(params.maxHtMoney):'null') +'/'
            + (params.area? encodeURIComponent(params.area.join(',')):''),
        method: 'GET',
    })
}
function listHzReports(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/hz-reports/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function listZcwj(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/zcwj/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size) +'/'
            + (params.startDate? encodeURIComponent(params.startDate) +'/':'')
            + (params.endDate? encodeURIComponent(params.endDate) +'/' :'')
            + (params.releaseByClassifyLike? encodeURIComponent(params.releaseByClassifyLike):'null') +'/'
            + (params.keyword? encodeURIComponent(params.keyword):'null') +'/'
            + (params.postNameLike? encodeURIComponent(params.postNameLike):'null') +'/'
            + (params.area? encodeURIComponent(params.area.join(',')):''),
        method: 'GET',
    })
}
function getZhanhuiTotalStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/total-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            +encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}
function getChangguanTotalStat() {
    return axios({
        url: config.backendUrl + 'changguan/total-stat',
        method: 'GET',
    })
}
function listAllIndustries() {
    return axios({
        url: config.backendUrl + 'industries',
        method: 'GET',
    })
}
function getZhanhuiJbkssjYearStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/year-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            +encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}
function getZhanhuiDwStat1(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/dw-stat1/'
            + encodeURIComponent(params.dwType)+'/'+encodeURIComponent(params.limit),
        method: 'GET',
    })
}
function getZhanhuiDwStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/dw-stat/'
            + encodeURIComponent(params.dwType)+'/'+encodeURIComponent(params.limit)+'/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            + (params.type ? encodeURIComponent(params.type) : "null") + '/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + (params.sshy ? encodeURIComponent(params.sshy) : "null"),
        method: 'GET',
    })
}
function getZhanhuiAreaMonthStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/area-month-stat/' + encodeURIComponent(params.year),
        method: 'GET',
    })
}
function listMof(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/mof/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function getChangguanMjStatByArea() {
    return axios({
        url: config.backendUrl + 'changguan/mj-stat-by-area',
        method: 'GET'
    })
}
function getChangguanCountStatByArea() {
    return axios({
        url: config.backendUrl + 'changguan/count-stat-by-area',
        method: 'GET'
    })
}
function getChangguanMjStatByCity() {
    return axios({
        url: config.backendUrl + 'changguan/mj-stat-by-city',
        method: 'GET'
    })
}
function getChangguanCountStatByCity() {
    return axios({
        url: config.backendUrl + 'changguan/count-stat-by-city',
        method: 'GET'
    })
}
function listChangguanZhanhuiInfo(params) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/changguan-zhanhui-info/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size)
            +'/' +encodeURIComponent(params.sort)+'/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null"),
        method: 'GET',
    })
}
function listChangguanZhanhuiInfo1(params) {
    return axios({
        url: config.backendUrl + 'changguan/changguan-zhanhui-info',
        method: 'GET',
        params
    })
}
function listHuixiaoerChangguan(params) {
    return axios({
        url: config.backendUrl + 'news/hxe-changguan',
        method: 'GET',
        params
    })
}
function listHuixiaoerChangguanInit(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/hxe-changguan/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size),
        method: 'GET',
    })
}
function getHuixiaoerChangguanById(id) {
    return axios({
        url: config.backendUrl + 'news/hxe-changguan/'+encodeURIComponent(id),
        method: 'GET',
    })
}
function getZhanhuiJbcgStat(params) {
    return axios({
        url: config.backendUrl + 'rewrite/zhanhui/jbcg-stat/'
            + encodeURIComponent(params.startDate) +'/' + encodeURIComponent(params.endDate)+'/'
            +encodeURIComponent(params.type)+'/'
            + (params.area ? encodeURIComponent(params.area) : "null") + '/'
            + encodeURIComponent(params.limit),
        method: 'GET',
    })
}
function listChangguanMonthlyKzl(cgid,startDate,endDate) {
    return axios({
        url: config.backendUrl + 'rewrite/changguan/'+ cgid + '/monthly-kzl/'
            +encodeURIComponent(startDate)+'/'+encodeURIComponent(endDate),
        method: 'GET',
    })
}
function getAttachmentUrl(name){
    return name?config.gatewayUrl + 'file/download?name=' + encodeURIComponent(name):null
}
function listTjjNews(params) {
    return axios({
        url: config.backendUrl + 'rewrite/news/tjj/'
            +encodeURIComponent(params.page)+'/'+encodeURIComponent(params.size)
            +'/'+encodeURIComponent(params.groupname)
        ,
        method: 'GET',
    })
}
export default {
    listZhanhui,
    listZhanhui1,
    getZhanhuiProvinceStat,
    getZhanhuiCityStat,
    getZhanhuiById,
    listZhanhuiNews,
    listChangguanNews,
    getChangguanById,
    getChangguanByName,
    getZhanhuiMjStat,
    getZhanhuiSshyStat,
    getZhanhuiJbzqStat,
    listChangguan,
    getChangguanProvinceStat,
    getChangguanCityStat,
    getChangguanMjStat,
    listChangguanKzl,
    listAreaKzl,
    statChangguanByZhanhuiCount,
    statChangguanByZhanhuiMj,
    listQaData,
    listFunding,
    listPenalty,
    listBidding,
    listHzReports,
    listZcwj,
    getZhanhuiTotalStat,
    getChangguanTotalStat,
    listAllIndustries,
    getZhanhuiJbkssjYearStat,
    getZhanhuiDwStat1,
    getZhanhuiDwStat,
    getZhanhuiAreaMonthStat,
    listMof,
    getChangguanMjStatByArea,
    getChangguanCountStatByArea,
    getChangguanMjStatByCity,
    getChangguanCountStatByCity,
    listChangguanZhanhuiInfo,
    listChangguanZhanhuiInfo1,
    listHuixiaoerChangguan,
    listHuixiaoerChangguanInit,
    getHuixiaoerChangguanById,
    getZhanhuiJbcgStat,
    listChangguanMonthlyKzl,
    getAttachmentUrl,
    listTjjNews
}
